<template>
  <c-box
    width="100%"
  >
    <c-flex
      width="100%"
      align-items="center"
      gap="48px"
    >
      <c-button
        v-if="isRoleAdmin"
        margin-top="17.5px"
        variant="ghost"
        display="flex"
        align-items="center"
        gap="1rem"
        @click="$router.back()"
      >
        <c-image
          w="24px"
          h="24px"
          object-fit="cover"
          :src="require('@/assets/icon-chevron-left.svg')"
        />
        <c-text
          font-size="16px"
          color="primary.400"
          font-weight="500"
        >
          Kembali
        </c-text>
      </c-button>

      <BreadcrumbPath
        v-if="isRoleAdmin"
        :paths="[
          {
            label: 'Manajemen Nilai Gizi',
            href: { name:'admin.glossary' },
          },
          {
            label: 'Detail Nilai Gizi',
            isCurrent: true
          },
        ]"
      />

      <Portal
        v-if="!isRoleAdmin"
        to="breadcrumb"
      >
        <BreadcrumbPath
          px="90px"
          py="20px"
          :paths="breadcrumbPath"
        />
      </Portal>
    </c-flex>
    <c-box
      width="100%"
      max-width="1270px"
      background-color="#FFF"
      margin-bottom="16px"
      :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
      :border-radius="['0px', '16px']"
      :padding="['16px', '20px']"
      :min-height="['unset', '74vh']"
    >
      <c-box
        max-width="800px"
        margin="auto"
      >
        <c-flex
          width="100%"
          justify-content="space-between"
          align-items="center"
          margin-bottom="20px"
        >
          <c-text
            :display="['none', 'block']"
            font-size="28px"
            font-weight="700"
            color="#111"
          >
            Detail Nilai Gizi
          </c-text>
          <c-flex
            v-if="isRoleAdmin"
            gap="16px"
          >
            <c-button
              variant="solid"
              variant-color="danger"
              border-radius="60px"
              min-w="114px"
              h="auto"
              py="13px"
              px="18px"
              font-size="16px"
              font-weight="500"
              line-height="24px"
              gap="8px"
              @click="isOpenModalConfirmationDelete = true"
            >
              <inline-svg
                :src="require('@/assets/icons/icon-trash.svg')"
                height="20"
                width="20"
                fill="#FFF"
              />
              Hapus
            </c-button>
            <c-button
              variant="solid"
              variant-color="primary"
              border-radius="60px"
              min-w="114px"
              h="auto"
              py="13px"
              px="18px"
              font-size="16px"
              font-weight="500"
              line-height="24px"
              gap="8px"
              @click="() => {
                $router.push({
                  name: 'admin.glossary-edit',
                  params: {
                    glossaryId: $route.params.glossaryId
                  }
                })
              }"
            >
              <inline-svg
                :src="require('@/assets/icons/icon-edit-2.svg')"
                height="20"
                width="20"
                fill="#FFF"
              />
              Edit
            </c-button>
          </c-flex>
        </c-flex>

        <c-text
          :display="['block', 'none']"
          font-size="20px"
          font-weight="700"
          text-align="center"
          margin-bottom="8px"
        >
          {{ glossary?.foodName || '-' }}
        </c-text>

        <c-flex
          gap="32px"
          :flex-direction="['column', 'row']"
          justi
        >
          <c-box @click="isOpenModalImage = true">
            <c-image
              cursor="pointer"
              :src="getPhotoBlankGray(glossary?.photoUrl)"
              border-radius="12px"
              :height="['100%','200px']"
              :width="['100%','200px']"
              object-fit="cover"
            />
          </c-box>

          <c-flex
            flex-direction="column"
            justify-content="center"
          >
            <c-text
              :display="['none','block']"
              font-size="20px"
              font-weight="700"
            >
              {{ glossary?.foodName || '-' }}
            </c-text>
            <c-text
              :margin-top="['0', '16px']"
              :font-size="['14px','16px']"
              font-weight="400"
              color="primary.400"
            >
              Golongan Makanan
            </c-text>
            <c-text
              font-weight="500"
              :font-size="['14px','18px']"
            >
              {{ glossary?.foodGroup || '-' }}
            </c-text>
            <c-text
              :margin-top="['24px', '8px']"
              :font-size="['14px','16px']"
              font-weight="400"
              color="primary.400"
            >
              Nama Bahan Dasar
            </c-text>
            <c-text
              font-weight="500"
              :font-size="['14px','18px']"
            >
              {{ glossary?.basicFoodIngredient || '-' }}
            </c-text>
          </c-flex>
        </c-flex>

        <c-flex
          v-if="listFoodIngridients.length >= 1"
          flex-direction="column"
          gap="3px"
        >
          <c-text
            :margin-top="['24px', '32px']"
            :font-size="['14px','16px']"
            font-weight="400"
            color="primary.400"
          >
            Komposi (Per porsi)
          </c-text>
          <c-box
            as="ul"
            padding-left="26px"
          >
            <c-text
              v-for="(item, index) in listFoodIngridients || []"
              :key="index"
              as="li"
              font-weight="500"
              :font-size="['14px','18px']"
            >
              {{ item }}
            </c-text>
          </c-box>
        </c-flex>

        <c-flex
          v-if="isRoleAdmin || isRoleNutritionist"
          width="100%"
          background-color="#00947D"
          border-radius="10px"
          padding="12px"
          margin-top="32px"
        >
          <c-flex
            width="100%"
            flex-direction="column"
            align-items="center"
            color="white"
          >
            <c-text font-size="16px">
              Takaran Saji/Porsi
            </c-text>
            <c-text
              font-size="18px"
              font-weight="600"
            >
              {{ glossary?.servingSizePerPortion?.servingSizePerPortion || '-' }} {{ glossary?.servingSizePerPortion?.servingSizeUnitPerPortion || '-' }}
            </c-text>
          </c-flex>
          <c-flex
            width="100%"
            flex-direction="column"
            align-items="center"
            color="white"
          >
            <c-text font-size="16px">
              Ukuran Rumah Tanggal/Porsi
            </c-text>
            <c-text
              font-size="18px"
              font-weight="600"
            >
              {{ glossary?.householdMeasurementPerPortion?.householdMeasurementPerPortion || '-' }}  {{ glossary?.householdMeasurementPerPortion?.householdMeasurementUnitPerPortion || '-' }}
            </c-text>
          </c-flex>
        </c-flex>

        <c-flex
          gap="16px"
          margin-top="32px"
        >
          <BaseInputText
            :value="glossary.servingSize.servingSize"
            label="Takaran Saji"
            placeholder="Masukkan Takaran Saji"
            full-width
            :input-right-addon="glossary?.servingSize?.servingSizeUnit || '-'"
            :is-invalid="isInvalidField($v.glossary.servingSize.servingSize)"
            :invalid-text="parseErrors('Takaran Saji', $v.glossary.servingSize.servingSize)"
            @input="(value) => {
              glossary.servingSize.servingSize = value
              if (value >= 1 && !isNaN(value)) {
                glossary.householdMeasurement.householdMeasurement = Math.floor(value / oldServingSize * oldHouseholdMeasurement)
              }
            }"
            @blur="$v.glossary.servingSize.servingSize.$touch"
          />
          <BaseInputText
            :value="glossary.householdMeasurement.householdMeasurement"
            label="Ukuran Rumah Tangga"
            placeholder="Masukkan Ukuran Rumah Tangga"
            full-width
            :input-right-addon="glossary?.householdMeasurement?.householdMeasurementUnit || '-'"
            :is-invalid="isInvalidField($v.glossary.householdMeasurement.householdMeasurement)"
            :invalid-text="parseErrors('Ukuran Rumah Tangga', $v.glossary.householdMeasurement.householdMeasurement)"
            @input="(value) => {
              glossary.householdMeasurement.householdMeasurement = value
              if (value >= 1 && !isNaN(value)) {
                glossary.servingSize.servingSize = Math.floor(value / oldHouseholdMeasurement * oldServingSize)
              }
            }"
            @blur="$v.glossary.householdMeasurement.householdMeasurement.$touch"
          />
        </c-flex>

        <c-box margin="20px 0">
          <c-box
            as="table"
            width="100%"
          >
            <c-box
              as="tr"
              background-color="#00A68C"
            >
              <c-box
                as="td"
                padding="10px 16px"
                border-top-left-radius="8px"
              >
                <c-text
                  :font-size="['16px','18px']"
                  font-weight="500"
                  color="white"
                >
                  Informasi Nilai Gizi
                </c-text>
              </c-box>
              <c-box
                as="td"
                colspan="2"
                padding="10px 16px"
                border-top-right-radius="8px"
                width="1%"
              >
                <c-flex justify-content="flex-end">
                  <c-text
                    font-weight="500"
                    color="primary.400"
                    text-align="end"
                    background-color="white"
                    padding="4px 6px"
                    border-radius="6px"
                    white-space="nowrap"
                    :font-size="['14px','18px']"
                  >
                    {{ recalculateNutrition.description || '0' }}
                  </c-text>
                </c-flex>
              </c-box>
            </c-box>
            <c-box
              as="tr"
            >
              <c-box
                as="td"
                padding="20px 0 0 16px"
                width="100%"
              >
                <c-text
                  font-weight="500"
                  border-bottom="1px solid #C4C4C4"
                  padding-bottom="6px"
                  :font-size="['14px','18px']"
                >
                  Energi
                </c-text>
              </c-box>
              <c-box
                as="td"
                width="1%"
                padding="20px 0 0 0"
              >
                <c-text
                  :font-size="['14px','18px']"
                  font-weight="500"
                  white-space="nowrap"
                  text-align="end"
                  border-bottom="1px solid #C4C4C4"
                  padding-bottom="6px"
                  padding-right="16px"
                >
                  {{ recalculateNutrition?.calories?.calories || '0' }}
                </c-text>
              </c-box>
              <c-box
                as="td"
                width="0.01%"
                padding="20px 16px 0 0"
              >
                <c-text
                  :font-size="['14px','18px']"
                  font-weight="500"
                  white-space="nowrap"
                  text-align="end"
                  border-bottom="1px solid #C4C4C4"
                  padding-bottom="6px"
                >
                  {{ glossary?.nutritionContent?.calories?.unit || '-' }}
                </c-text>
              </c-box>
            </c-box>

            <c-box
              as="tr"
            >
              <c-box
                as="td"
                padding="20px 0 0 16px"
                width="100%"
              >
                <c-text
                  :font-size="['14px','18px']"
                  font-weight="500"
                  border-bottom="1px solid #C4C4C4"
                  padding-bottom="6px"
                >
                  Protein
                </c-text>
              </c-box>
              <c-box
                as="td"
                width="1%"
                padding="20px 0 0 0"
              >
                <c-text
                  :font-size="['14px','18px']"
                  font-weight="500"
                  white-space="nowrap"
                  text-align="end"
                  border-bottom="1px solid #C4C4C4"
                  padding-bottom="6px"
                  padding-right="16px"
                >
                  {{ recalculateNutrition?.protein?.protein || '0' }}
                </c-text>
              </c-box>
              <c-box
                as="td"
                width="0.01%"
                padding="20px 16px 0 0"
              >
                <c-text
                  :font-size="['14px','18px']"
                  font-weight="500"
                  white-space="nowrap"
                  text-align="end"
                  border-bottom="1px solid #C4C4C4"
                  padding-bottom="6px"
                >
                  {{ glossary?.nutritionContent?.protein?.unit || '-' }}
                </c-text>
              </c-box>
            </c-box>

            <c-box
              as="tr"
            >
              <c-box
                as="td"
                padding="20px 0 0 16px"
                width="100%"
              >
                <c-text
                  :font-size="['14px','18px']"
                  font-weight="500"
                  :border-bottom="glossary?.nutritionContent?.fat?.sub?.length >= 1 ? '' : '1px solid #C4C4C4'"
                  padding-bottom="6px"
                >
                  Lemak
                </c-text>
              </c-box>
              <c-box
                as="td"
                width="1%"
                padding="20px 0 0 0"
              >
                <c-text
                  :font-size="['14px','18px']"
                  font-weight="500"
                  white-space="nowrap"
                  text-align="end"
                  :border-bottom="glossary?.nutritionContent?.fat?.sub?.length >= 1 ? '' : '1px solid #C4C4C4'"
                  padding-bottom="6px"
                  padding-right="16px"
                >
                  {{ recalculateNutrition?.fat?.fat || '0' }}
                </c-text>
              </c-box>
              <c-box
                as="td"
                width="0.01%"
                padding="20px 16px 0 0"
              >
                <c-text
                  :font-size="['14px','18px']"
                  font-weight="500"
                  white-space="nowrap"
                  text-align="end"
                  :border-bottom="glossary?.nutritionContent?.fat?.sub?.length >= 1 ? '' : '1px solid #C4C4C4'"
                  padding-bottom="6px"
                >
                  {{ glossary?.nutritionContent?.fat?.unit || '-' }}
                </c-text>
              </c-box>
            </c-box>
            <c-box
              v-for="(item, index) in recalculateNutrition?.fat?.sub || []"
              :key="index"
              as="tr"
            >
              <c-box
                as="td"
                padding="6px 16px 0 16px"
                width="100%"
                colspan="3"
              >
                <c-box
                  as="table"
                  width="100%"
                >
                  <c-box
                    as="tr"
                  >
                    <c-box
                      as="td"
                      padding="0 0 0 16px"
                      width="100%"
                      :border-bottom="glossary?.nutritionContent?.fat?.sub?.length - 1 === index ?'1px solid #C4C4C4':''"
                    >
                      <c-text
                        :font-size="['14px','16px']"
                        font-weight="400"
                        padding-bottom="6px"
                        color="primary.400"
                      >
                        {{ item?.name || '-' }}
                      </c-text>
                    </c-box>
                    <c-box
                      as="td"
                      width="1%"
                      padding="0 0 0 0"
                      :border-bottom="glossary?.nutritionContent?.fat?.sub?.length - 1 === index ?'1px solid #C4C4C4':''"
                    >
                      <c-text
                        :font-size="['14px','16px']"
                        font-weight="400"
                        white-space="nowrap"
                        text-align="end"
                        padding-bottom="6px"
                        padding-right="16px"
                        color="primary.400"
                      >
                        {{ item?.amount || '0' }}
                      </c-text>
                    </c-box>
                    <c-box
                      as="td"
                      width="0.01%"
                      padding="0 16px 0 0"
                      :border-bottom="glossary?.nutritionContent?.fat?.sub?.length - 1 === index ?'1px solid #C4C4C4':''"
                    >
                      <c-text
                        :font-size="['14px','16px']"
                        font-weight="400"
                        white-space="nowrap"
                        text-align="end"
                        padding-bottom="6px"
                        color="primary.400"
                      >
                        {{ item?.unit || '-' }}
                      </c-text>
                    </c-box>
                  </c-box>
                </c-box>
              </c-box>
            </c-box>

            <c-box
              as="tr"
            >
              <c-box
                as="td"
                padding="20px 0 0 16px"
                width="100%"
              >
                <c-text
                  :font-size="['14px','18px']"
                  font-weight="500"
                  :border-bottom="glossary?.nutritionContent?.carbohydrate?.sub?.length >= 1 ? '' : '1px solid #C4C4C4'"
                  padding-bottom="6px"
                >
                  Karbohidrat
                </c-text>
              </c-box>
              <c-box
                as="td"
                width="1%"
                padding="20px 0 0 0"
              >
                <c-text
                  :font-size="['14px','18px']"
                  font-weight="500"
                  white-space="nowrap"
                  text-align="end"
                  :border-bottom="glossary?.nutritionContent?.carbohydrate?.sub?.length >= 1 ? '' : '1px solid #C4C4C4'"
                  padding-bottom="6px"
                  padding-right="16px"
                >
                  {{ recalculateNutrition?.carbohydrate?.carbohydrate || '0' }}
                </c-text>
              </c-box>
              <c-box
                as="td"
                width="0.01%"
                padding="20px 16px 0 0"
              >
                <c-text
                  :font-size="['14px','18px']"
                  font-weight="500"
                  white-space="nowrap"
                  text-align="end"
                  :border-bottom="glossary?.nutritionContent?.carbohydrate?.sub?.length >= 1 ? '' : '1px solid #C4C4C4'"
                  padding-bottom="6px"
                >
                  {{ glossary?.nutritionContent?.carbohydrate?.unit || '-' }}
                </c-text>
              </c-box>
            </c-box>
            <c-box
              v-for="(item, index) in recalculateNutrition?.carbohydrate?.sub || []"
              :key="index + 99"
              as="tr"
            >
              <c-box
                as="td"
                padding="6px 16px 0 16px"
                width="100%"
                colspan="3"
              >
                <c-box
                  as="table"
                  width="100%"
                >
                  <c-box
                    as="tr"
                  >
                    <c-box
                      as="td"
                      padding="0 0 0 16px"
                      width="100%"
                      :border-bottom="glossary?.nutritionContent?.carbohydrate?.sub?.length - 1 === index ?'1px solid #C4C4C4':''"
                    >
                      <c-text
                        :font-size="['14px','16px']"
                        font-weight="400"
                        padding-bottom="6px"
                        color="primary.400"
                      >
                        {{ item?.name || '-' }}
                      </c-text>
                    </c-box>
                    <c-box
                      as="td"
                      width="1%"
                      padding="0 0 0 0"
                      :border-bottom="glossary?.nutritionContent?.carbohydrate?.sub?.length - 1 === index ?'1px solid #C4C4C4':''"
                    >
                      <c-text
                        :font-size="['14px','16px']"
                        font-weight="400"
                        white-space="nowrap"
                        text-align="end"
                        padding-bottom="6px"
                        padding-right="16px"
                        color="primary.400"
                      >
                        {{ item?.amount || '0' }}
                      </c-text>
                    </c-box>
                    <c-box
                      as="td"
                      width="0.01%"
                      padding="0 16px 0 0"
                      :border-bottom="glossary?.nutritionContent?.carbohydrate?.sub?.length - 1 === index ?'1px solid #C4C4C4':''"
                    >
                      <c-text
                        :font-size="['14px','16px']"
                        font-weight="400"
                        white-space="nowrap"
                        text-align="end"
                        padding-bottom="6px"
                        color="primary.400"
                      >
                        {{ item?.unit || '-' }}
                      </c-text>
                    </c-box>
                  </c-box>
                </c-box>
              </c-box>
            </c-box>

            <c-box
              as="tr"
            >
              <c-box
                as="td"
                padding="20px 0 0 16px"
                width="100%"
              >
                <c-text
                  :font-size="['14px','18px']"
                  font-weight="500"
                  border-bottom="1px solid #C4C4C4"
                  padding-bottom="6px"
                >
                  Kolestrol
                </c-text>
              </c-box>
              <c-box
                as="td"
                width="1%"
                padding="20px 0 0 0"
              >
                <c-text
                  :font-size="['14px','18px']"
                  font-weight="500"
                  white-space="nowrap"
                  text-align="end"
                  border-bottom="1px solid #C4C4C4"
                  padding-bottom="6px"
                  padding-right="16px"
                >
                  {{ recalculateNutrition?.cholesterol?.cholesterol || '0' }}
                </c-text>
              </c-box>
              <c-box
                as="td"
                width="0.01%"
                padding="20px 16px 0 0"
              >
                <c-text
                  :font-size="['14px','18px']"
                  font-weight="500"
                  white-space="nowrap"
                  text-align="end"
                  border-bottom="1px solid #C4C4C4"
                  padding-bottom="6px"
                >
                  {{ glossary?.nutritionContent?.cholesterol?.unit || '-' }}
                </c-text>
              </c-box>
            </c-box>

            <c-box
              as="tr"
            >
              <c-box
                as="td"
                padding="20px 0 0 16px"
                width="100%"
              >
                <c-text
                  :font-size="['14px','18px']"
                  font-weight="500"
                  border-bottom="1px solid #C4C4C4"
                  padding-bottom="6px"
                >
                  Natrium (garam)
                </c-text>
              </c-box>
              <c-box
                as="td"
                width="1%"
                padding="20px 0 0 0"
              >
                <c-text
                  :font-size="['14px','18px']"
                  font-weight="500"
                  white-space="nowrap"
                  text-align="end"
                  border-bottom="1px solid #C4C4C4"
                  padding-bottom="6px"
                  padding-right="16px"
                >
                  {{ recalculateNutrition?.natrium?.natrium || '0' }}
                </c-text>
              </c-box>
              <c-box
                as="td"
                width="0.01%"
                padding="20px 16px 0 0"
              >
                <c-text
                  :font-size="['14px','18px']"
                  font-weight="500"
                  white-space="nowrap"
                  text-align="end"
                  border-bottom="1px solid #C4C4C4"
                  padding-bottom="6px"
                >
                  {{ glossary?.nutritionContent?.natrium?.unit || '-' }}
                </c-text>
              </c-box>
            </c-box>

            <c-box
              as="tr"
            >
              <c-box
                as="td"
                padding="20px 0 0 16px"
                width="100%"
              >
                <c-text
                  :font-size="['14px','18px']"
                  font-weight="500"
                  border-bottom="1px solid #C4C4C4"
                  padding-bottom="6px"
                >
                  Zat Besi
                </c-text>
              </c-box>
              <c-box
                as="td"
                width="1%"
                padding="20px 0 0 0"
              >
                <c-text
                  :font-size="['14px','18px']"
                  font-weight="500"
                  white-space="nowrap"
                  text-align="end"
                  border-bottom="1px solid #C4C4C4"
                  padding-bottom="6px"
                  padding-right="16px"
                >
                  {{ recalculateNutrition?.iron?.iron || '0' }}
                </c-text>
              </c-box>
              <c-box
                as="td"
                width="0.01%"
                padding="20px 16px 0 0"
              >
                <c-text
                  :font-size="['14px','18px']"
                  font-weight="500"
                  white-space="nowrap"
                  text-align="end"
                  border-bottom="1px solid #C4C4C4"
                  padding-bottom="6px"
                >
                  {{ glossary?.nutritionContent?.iron?.unit || '-' }}
                </c-text>
              </c-box>
            </c-box>

            <c-box
              v-for="(item, index) in recalculateNutrition?.additonalNutritionContent || []"
              :key="index + 888"
              as="tr"
            >
              <c-box
                as="td"
                padding="20px 0 0 16px"
                width="100%"
              >
                <c-text
                  :font-size="['14px','18px']"
                  font-weight="500"
                  border-bottom="1px solid #C4C4C4"
                  padding-bottom="6px"
                >
                  {{ item?.name || '-' }}
                </c-text>
              </c-box>
              <c-box
                as="td"
                width="1%"
                padding="20px 0 0 0"
              >
                <c-text
                  :font-size="['14px','18px']"
                  font-weight="500"
                  white-space="nowrap"
                  text-align="end"
                  border-bottom="1px solid #C4C4C4"
                  padding-bottom="6px"
                  padding-right="16px"
                >
                  {{ item?.amount || '0' }}
                </c-text>
              </c-box>
              <c-box
                as="td"
                width="0.01%"
                padding="20px 16px 0 0"
              >
                <c-text
                  :font-size="['14px','18px']"
                  font-weight="500"
                  white-space="nowrap"
                  text-align="end"
                  border-bottom="1px solid #C4C4C4"
                  padding-bottom="6px"
                >
                  {{ item?.unit || '-' }}
                </c-text>
              </c-box>
            </c-box>
          </c-box>
        </c-box>

        <c-box
          margin="20px 0"
        >
          <c-text
            width="100%"
            padding="10px 16px"
            border-bottom="1px solid #888"
            :font-size="['18px','20px']"
            color="primary.400"
            font-weight="700"
            margin-bottom="16px"
          >
            Zat Gizi Dominan
          </c-text>
          <c-box
            padding-inline="16px"
          >
            <c-grid
              :template-columns="['repeat(auto-fill, minmax(75px, 1fr))', 'repeat(auto-fill, minmax(130px, 1fr))']"
              gap="3"
            >
              <c-aspect-ratio-box
                v-for="(item, index) in mainNutritionContent || []"
                :key="index"
                :ratio="1"
                position="relative"
              >
                <c-flex
                  border="1.5px solid #FDEDCB"
                  border-radius="8px"
                  flex-direction="column"
                  justify-content="center"
                  align-items="center"
                  width="100%"
                  height="100%"
                  background-color="#FFFCDD"
                >
                  <c-image
                    :src="getPhotoBlankGray(item?.photoUrl)"
                    alt="icon zat gizi dominan"
                    :width="['30px', '50px']"
                    :height="['30px', '50px']"
                  />
                  <c-text
                    position="absolute"
                    top="10px"
                    left="15px"
                    :font-size="['14px','18px']"
                    font-weight="500"
                    color="primary.400"
                  >
                    {{ index+1 }}
                  </c-text>
                  <c-text
                    :font-size="['14px','16px']"
                    font-weight="400"
                    color="primary.400"
                    text-align="center"
                  >
                    {{ item?.value || '-' }}
                  </c-text>
                </c-flex>
              </c-aspect-ratio-box>
            </c-grid>
          </c-box>

          <c-box
            margin="40px 0 20px 0"
          >
            <c-text
              width="100%"
              padding="10px 16px"
              border-bottom="1px solid #888"
              :font-size="['18px','20px']"
              color="primary.400"
              font-weight="700"
              margin-bottom="16px"
            >
              Lainnya
            </c-text>

            <c-grid
              w="100%"
              padding-inline="16px"
              :template-columns="['repeat(1, 1fr)']"
              :gap="['20px']"
              mx="auto"
            >
              <c-box>
                <c-text
                  :font-size="['14px','16px']"
                  font-weight="400"
                  color="primary.400"
                >
                  Makanan Khas
                </c-text>
                <c-text
                  :font-size="['14px','18px']"
                  font-weight="500"
                  color="#333"
                >
                  {{ glossary?.other?.cuisine || '-' }}
                </c-text>
              </c-box>
              <c-box>
                <c-text
                  :font-size="['14px','16px']"
                  font-weight="400"
                  color="primary.400"
                >
                  Makanan
                </c-text>
                <c-text
                  :font-size="['14px','18px']"
                  font-weight="500"
                  color="#333"
                >
                  {{ glossary?.other?.tag || '-' }}
                </c-text>
              </c-box>
            </c-grid>
          </c-box>
        </c-box>

        <!-- MODAL CONFIRMATION DELETE DATA -->
        <BaseModal
          :is-open="isOpenModalConfirmationDelete"
          :close-on-overlay-click="false"
          :with-button-close="false"
        >
          <template #header>
            <c-box
              display="flex"
              justify-content="center"
              margin="24px 0 0 0"
            >
              <CImage
                width="150px"
                object-fit="cover"
                :src="require('@/assets/images/image-delete.svg')"
                alt="Image Confirmation"
              />
            </c-box>
          </template>
          <template #body>
            <c-box
              display="flex"
              flex-direction="column"
              align-items="center"
              px="24px"
              py="16px"
              text-align="center"
              max-width="500px"
              mx="auto"
            >
              <BaseText
                size-mobile="16px"
                size-desktop="20px"
                color="primary.400"
              >
                Anda yakin ingin hapus data nilai gizi “{{ glossary?.foodName }}”?
              </BaseText>
            </c-box>
          </template>
          <template #footer>
            <c-box
              width="100%"
              padding-left="24px"
              padding-right="24px"
              margin="24px 0"
            >
              <c-flex gap="8px">
                <BaseButton
                  :left-svg-icon="require('@/assets/icons/icon-circle-close.svg')"
                  left-svg-icon-color="#008C81"
                  color="primary"
                  rounded="1000px"
                  width="100%"
                  variant="outlined"
                  @click="isOpenModalConfirmationDelete = false"
                >
                  Batal
                </BaseButton>
                <BaseButton
                  :right-svg-icon="require('@/assets/icons/icon-trash.svg')"
                  right-svg-icon-color="white"
                  color="danger"
                  rounded="1000px"
                  width="100%"
                  :is-loading="isDeleting"
                  @click="onDelete"
                >
                  Hapus
                </BaseButton>
              </c-flex>
            </c-box>
          </template>
        </BaseModal>

        <!-- MODAL SUCCESS DELETE DATA -->
        <BaseModal
          :is-open="isOpenModalSuccessDelete"
          :close-on-overlay-click="false"
          :with-button-close="false"
        >
          <template #header>
            <c-box
              display="flex"
              justify-content="center"
              margin="24px 0 0 0"
            >
              <CImage
                width="150px"
                object-fit="cover"
                :src="require('@/assets/images/success-image.svg')"
                alt="Image Confirmation"
              />
            </c-box>
          </template>
          <template #body>
            <c-box
              display="flex"
              flex-direction="column"
              align-items="center"
              px="24px"
              py="16px"
              text-align="center"
              max-width="500px"
              mx="auto"
            >
              <BaseText
                size-mobile="16px"
                size-desktop="20px"
                color="primary.400"
              >
                Data nilai gizi berhasil dihapus!
              </BaseText>
            </c-box>
          </template>
          <template #footer>
            <c-box
              width="100%"
              padding-left="24px"
              padding-right="24px"
              margin="24px 0"
            >
              <BaseButton
                :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
                right-svg-icon-color="white"
                color="primary"
                rounded="1000px"
                width="100%"
                @click="isOpenModalSuccessDelete = false"
              >
                Oke
              </BaseButton>
            </c-box>
          </template>
        </BaseModal>
        
        <!-- MODAL IMAGE -->
        <BaseModal
          :is-open="isOpenModalImage"
          close-on-overlay-click
          with-button-close
          :has-header="false"
          :has-footer="false"
          size="xl"
          @close="isOpenModalImage = false"
        >
          <template #body>
            <c-flex
              justify-content="center"
              align-items="center"
            >
              <c-image
                :src="getPhotoBlankGray(glossary?.photoUrl)"
              />
            </c-flex>
          </template>
        </BaseModal>
      </c-box>
    </c-box>
  </c-box>
</template>

<script>
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'
import { formatCurrency } from '@/utils/format-currency'
import { CBox, CButton, CFlex, CImage, CText, CGrid, CAspectRatioBox } from '@chakra-ui/vue'
import BaseButton from '@/components/elements/base-button.vue'
import BaseText from '@/components/elements/base-text.vue'
import BaseModal from '@/components/elements/base-modal.vue'
import BaseInputText from '@/components/elements/base-input-text.vue'
import {
  reqMealGlossaries_ClientNutritionist_getDetail,
  reqMealGlossaries_delete,
  reqMealGlossaries_getDetail,
} from '@/requests/dietela-api/admin/other'
import { parseErrorCatch } from '@/utils/parse-error-catch'
import generalMixin from '@/utils/general-mixins'
import { required, integer } from 'vuelidate/lib/validators'
import { isInvalidField } from '@/utils/vuelidate-helpers/is-invalid-field'
import { parseErrors } from '@/utils/vuelidate-helpers/parse-errors'

export default {
  name: 'AdminGlossaryDetail',
  components: {
    BaseInputText,
    BaseModal,
    BaseText,
    CBox,
    BaseButton,
    CImage,
    CButton,
    CText,
    CFlex,
    CGrid,
    CAspectRatioBox,
    BreadcrumbPath,
  },
  mixins: [generalMixin],
  data() {
    return {
      isOpenModalImage: false,

      oldServingSize: 0,
      oldHouseholdMeasurement: 0,
      glossary: {
        servingSize: {
          servingSize: 0,
        },
        householdMeasurement: {
          householdMeasurement: 0,
        },
      },

      isOpenModalConfirmationDelete: false,
      isOpenModalSuccessDelete: false,
      isDeleting: false,
    }
  },
  computed: {
    breadcrumbPath() {
      if (this.isClient) {
        return [
          { label: 'Dashboard', href: { name: 'client.index' } },
          { label: 'Nilai Gizi', href: { name: 'client.glossaries' } },
          { label: 'Detail Nilai Gizi', isCurrent: true },
        ]
      } else if (this.isRoleNutritionist) {
        return [
          { label: 'Beranda', href: { name: 'nutrisionist.managementClient' } },
          { label: 'Nilai Gizi', href: { name: 'client.glossaries' } },
          { label: 'Detail Nilai Gizi', isCurrent: true },
        ]
      }
      return []
    },
    glossaryId() {
      return this.$route.params.glossaryId
    },
    listFoodIngridients() {
      return this.glossary?.foodIngredient ? this.glossary?.foodIngredient?.trim().split('\n') || [] :[]
    },
    mainNutritionContent() {
      return [...(this.glossary?.mainNutritionContent || [])]?.sort((a, b) => parseInt(a.order) - parseInt(b.order)) || []
    },
    splitGlossaryNutritionContentDescription() {
      return {
        value: this.glossary?.nutritionContent?.description?.split(' ')?.[0] || 0,
        unit: this.glossary?.nutritionContent?.description?.split(' ')?.slice(1)?.join(' ') || '-',
      }
    },
    recalculateNutrition() {
      const divide = this.glossary.servingSize.servingSize / this.oldServingSize
      const remap = Object.keys(this.glossary?.nutritionContent || {}).reduce((acc, key) => {
        // skip description
        if (key === 'description') return acc
        if (Array.isArray(this.glossary?.nutritionContent[key])) {
          // handle additonalNutritionContent
          acc[key] = this.glossary?.nutritionContent[key]?.map((item) => ({
            ...item,
            amount: Math.floor(divide * item.amount),
          }))
        } else if(this.glossary?.nutritionContent[key]) {
        // handle nutritionContent
          acc[key] = {
            [key]: Math.floor(divide * this.glossary?.nutritionContent[key][key]),
            unit: this.glossary?.nutritionContent[key]?.unit || '-',
          }
        }
        // handle nutritionContent sub
        if (this.glossary?.nutritionContent[key]?.sub?.length >= 1) {
          acc[key].sub = this.glossary?.nutritionContent[key]?.sub?.map((item) => ({
            ...item,
            amount: Math.floor(divide * item.amount),
          }))
        }
        return acc
      }, {})
      return {
        description: `${Math.floor(divide * this.splitGlossaryNutritionContentDescription.value)} ${this.splitGlossaryNutritionContentDescription.unit}`,
        ...remap,
      }
    },
  },
  mounted() {
    this.init()
  },
  metaInfo: {
    title: 'Detail Nilai Gizi | Dietela',
  },
  methods: {
    parseErrors,
    isInvalidField,
    formatCurrency,
    async init() {
      try {
        let res = {}
        if (this.isRoleAdmin) {
          res = await reqMealGlossaries_getDetail(this.$store.getters.axios, { id: this.glossaryId })
        } else if (this.isClient || this.isRoleNutritionist) {
          res = await reqMealGlossaries_ClientNutritionist_getDetail(this.$store.getters.axios, { id: this.glossaryId })
        }
        this.glossary = res.data?.data
        this.oldServingSize = parseInt(res.data?.data?.servingSize?.servingSize || 0)
        this.oldHouseholdMeasurement = parseInt(res.data?.data?.householdMeasurement?.householdMeasurement || 0)
      } catch (e) {
        if (this.isRoleAdmin) {
          this.$router.replace({ name: 'admin.glossary' })
        } else if (this.isClient || this.isRoleNutritionist) {
          this.$router.replace({ name: 'client.glossaries' })
        }
        this.$toast ({
          status: 'error',
          title: 'Error',
          description: parseErrorCatch(e),
          duration: 2000,
        })
      }
    },
    async onDelete() {
      this.isDeleting = true
      try {
        await reqMealGlossaries_delete(this.$store.getters.axios, { ids: [this.glossaryId] })
        this.isOpenModalConfirmationDelete = false
        this.isOpenModalSuccessDelete = true
        setTimeout(() => {
          this.$router.replace({ name: 'admin.glossary' })
        }, 2000)
      } catch (e) {
        this.$toast ({
          status: 'error',
          title: 'Error',
          description: parseErrorCatch(e),
          duration: 2000,
        })
      } finally {
        this.isDeleting = false
      }
    },
  },
  validations() {
    return {
      glossary: {
        servingSize: {
          servingSize: {
            required,
            integer,
          },
        },
        householdMeasurement: {
          householdMeasurement: {
            required,
            integer,
          },
        },
      },
    }
  },
}
</script>
